<template>

  <div class="dialog " :class="isCTAForm ? 'act' : ''">
    <div class="cover"></div>
    <div class="inner prov_inner">
      <div class="top">
        <div class="title f_30">{{ title }}</div> <img @click="ctaFormClick" src="@/assets/imgl/cha.svg" alt="">
      </div>
      <div class="form">
        <el-form ref="saleFormRef" :model="saleForm" :rules="contactRules">
          <div class="top_tip f_18">
            {{ t('about.contact.applyRobotMsg') }} <br> {{ t('about.contact.callup') }} <span>{{ t('index.hotline') }}</span> {{ t('about.contact.service') }}
          </div>
          <div class="block">
            <!-- <div class="title">联系方式</div> -->
            <div class="list">
              <div class="item item3">
                <div class="tit f_16">
                  <span>*</span>
                  {{ t('form.field.userName') }}
                </div>
                <div class="input">
                  <el-form-item prop="userName">
                    <el-input :placeholder="t('form.input.userName')" :validate-event="isValidateEvent" v-model="saleForm.userName" />
                  </el-form-item>
                </div>
              </div>
              <div class="item item3">
                <div class="tit f_16">
                  <span>*</span>
                  {{ t('form.field.telephone') }}
                </div>
                <div class="input">
                  <el-form-item prop="phoneNumber">
                    <el-input :placeholder="t('form.input.telephone')" :validate-event="isValidateEvent" v-model="saleForm.phoneNumber" />
                  </el-form-item>
                </div>
              </div>
              <div class="item item3">
                <div class="tit f_16">
                  <span>*</span>
                  {{ t('form.field.email') }}
                </div>
                <div class="input">
                  <el-form-item prop="email">
                    <el-input :placeholder="t('form.input.email')" :validate-event="isValidateEvent" v-model="saleForm.email" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <!-- <div class="title">公司信息</div> -->
            <div class="list">
              <div class="item item3 sel" v-if="locale == 'zh'">
                <div class="tit f_16">
                  <span>*</span>
                  {{ t('form.field.city') }}
                </div>
                <el-form-item prop="city">
                  <el-cascader v-model="saleForm.city" :options="fixCity()" @change="cityChange()"
                    :validate-event="isValidateEvent" :placeholder="t('form.input.city')"></el-cascader>
                </el-form-item>
              </div>

              <div class="item item3" v-else>
                <div class="tit f_16"><span>*</span>{{ t('form.field.country') }}</div>
                <el-form-item prop="country">
                  <el-select filterable v-model="saleForm.country" :validate-event="isValidateEvent" class="m-2"
                      :placeholder="t('form.input.country')" size="large">
                      <el-option v-for="item in country" :key="item.value" :label="t(`country.${item.code}`)"
                        :value="item.value" />
                    </el-select>
                </el-form-item>
              </div>

              <div class="item item3">
                <div class="tit f_16">
                  <span>*</span>
                  {{ t('form.field.companyName') }}
                </div>
                <div class="input">
                  <el-form-item prop="companyName">
                    <el-input :placeholder="t('form.input.companyName')" :validate-event="isValidateEvent"
                      v-model="saleForm.companyName" />
                  </el-form-item>
                </div>
              </div>
              <template v-if="locale == 'zh'">
                <div class="item item3">
                  <div class="tit f_16">
                    <span>*</span>
                    {{ t('form.field.communicateMatter') }}
                  </div>
                  <div class="input">
                    <el-form-item prop="communicateMatter">
                      <el-select v-model="saleForm.communicateMatter" class="m-2" :validate-event="isValidateEvent"
                        :placeholder="t('form.input.communicateMatter')" size="large">
                        <el-option v-for="item in communicateMatterOptions" :key="item.value" :label="item.label"
                          :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </div>
              </div>
              </template>
              <template v-else>
                <div class="item item3">
                  <div class="tit f_16">
                    <span>*</span>
                    {{ t('form.field.industry') }}
                  </div>
                  <div class="input">
                    <el-form-item prop="industry">
                      <el-select v-model="saleForm.industry" class="m-2" :placeholder="t('form.input.industry')"
                        :validate-event="isValidateEvent" size="large">
                        <el-option v-for="item in industryList" :key="item.value" :label="item.label"
                          :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="item item3">
                  <div class="tit f_16">
                    <span>*</span>
                    {{ t('form.field.companyType') }}
                  </div>
                  <div class="input f_16">
                    <el-form-item prop="companyType">
                      <el-select v-model="saleForm.companyType" class="m-2" :validate-event="isValidateEvent"
                        :placeholder="t('form.field.companyType')" size="large">
                        <el-option v-for="item in companyTypeList" :key="item.value" :label="item.label"
                          :value="item.value" />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="block">
            <!-- <div class="title">信息留言</div> -->
            <div class="item" v-if="locale == 'zh'">
              <div class="tit f_16">
                <span>*</span>{{ t('form.field.coRequirements') }}
              </div>
              <el-form-item prop="specificRequirements">
                <el-input v-model="saleForm.specificRequirements" :validate-event="isValidateEvent"
                  :placeholder="t('form.input.coRequirements')" type="textarea" />
              </el-form-item>
            </div>
            <div class="item" v-else>
              <div class="tit f_16">
                <span>*</span>
                {{ t('form.field.comment') }}
              </div>
              <el-form-item prop="comment" class="f_16">
                <el-input v-model="saleForm.comment" :placeholder="t('form.field.comment')"
                  :validate-event="isValidateEvent" type="textarea" />
              </el-form-item>
            </div>
          </div>

          <div class="checkbox">
            <input type="checkbox" v-model="isAgree" id="comm">
            <label for="" class="f_14">{{ t('login.readAgree') }}<a :href="formatPath('/flsm')" target="_blank">{{ t('login.policy') }}</a></label>
          </div>
        </el-form>
        <a class="more f_16" @click="submitCrmForm">{{ t('form.submit.immediate') }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { crmCommApi, crmOverseasCommApi, checkCity } from '@/services/index.js'
import { useI18n } from 'vue-i18n'
import { formatPath } from '@/utils/common'

const { t, locale } = useI18n()
const props = defineProps({
  tag: String,
  title: String,
})

onMounted(() => {
})

const isCTAForm = ref(null)

const ctaFormClick = () => {
  isCTAForm.value = !isCTAForm.value
  $('html').toggleClass('fixed')
}


defineExpose({
  ctaFormClick,
});


const isAgree = ref(false)
const switchAgree = () => {
  isAgree.value = !isAgree.value
  // console.log(isAgree.value);
}

// 表单验证规则
import { contactRules, isValidateEvent } from '@/utils/rules.js'
import { fixCity } from '@/utils/getCity'
import { useIndustryList, useCompanyType } from '@/hooks/useDict'
import { country, lan } from '@/utils/country'
const { industryList } = useIndustryList(locale.value)
const { companyTypeList } = useCompanyType()

const district = ref(null)
const cityChange = () => {
  checkCity(saleForm.city[1].province).then(res => {
    if (!res.data) {
      checkCity(saleForm.city[1].city).then(res => {
        if (!res.data) {
          ElMessage({
            message: t('form.error.cityNotAvailable'),
            type: 'warning',
            offset: 200,
          })
          district.value = saleForm.city[1].province
        } else {
          district.value = res.data.district
        }
      })
    } else {
      district.value = res.data.district
    }
  })
}
import { ElMessage, ElLoading } from 'element-plus'

const saleFormRef = ref(null)
let saleForm = reactive({
  userName: null,
  phoneNumber: null,
  email: null,
  communicateMatter: null,
  city: null,
  companyName: null,
  specificRequirements: null,
  industry: null,
  companyType: null,
  comment: null,
  country: country.filter(item=> item.code == lan[locale.value])[0]?.value
})
const communicateMatterOptions = reactive([
  {
    label: t('form.field.productInfo'),
    value: 0
  },
  {
    label: t('form.field.cooperationInquiry'),
    value: 1
  },
  {
    label: t('form.field.afterSalesService'),
    value: 2
  },
])

const submitCrmForm = (type) => {
  saleFormRef.value.validate((valid, fields) => {
    if (valid) {
      // console.log(saleForm);
      if (isAgree.value) {
        // ElMessage({
        //   message: '正在提交，请稍候。。。',
        //   offset: 200,
        // })
        if (locale.value == 'zh') {
          crmCommApi({
            userName: saleForm.userName,
            phoneNumber: saleForm.phoneNumber,
            email: saleForm.email,
            communicateMatter: saleForm.communicateMatter,
            city: district.value,
            companyName: saleForm.companyName,
            specificRequirements: saleForm.specificRequirements,
            // 成为经销商 官网留言 JAKA+合作伙伴 申请样机
            tags: [props.tag],
            clueName: '官网',
          }).then(res => {
            // console.log(res);
            if (res.success) {
              ElMessage({
                message: t('form.submit.success'),
                type: 'success',
                offset: 200,
              })
              isCTAForm.value = !isCTAForm.value

              $('html').removeClass('fixed')
              saleForm.userName = null
              saleForm.phoneNumber = null
              saleForm.email = null
              saleForm.communicateMatter = null
              saleForm.city = null
              saleForm.companyName = null
              saleForm.specificRequirements = null
              isAgree.value = false
            } else {
              ElMessage({
                message: t('form.submit.fail'),
                type: 'error',
                offset: 200,
              })
            }
          })
        } else {
          crmOverseasCommApi({
            userName: saleForm.userName,
            phone: saleForm.phoneNumber,
            email: saleForm.email,
            companyName: saleForm.companyName,
            industry: [saleForm.industry],
            companyType: saleForm.companyType,
            comment: saleForm.comment,
            country: saleForm.country,
          }).then(res => {
            // console.log(res);
            if (res.success) {
              ElMessage({
                message: t('form.submit.success'),
                type: 'success',
                offset: 200,
              })
              isCTAForm.value = !isCTAForm.value

              $('html').removeClass('fixed')
              saleForm.userName = null
              saleForm.phoneNumber = null
              saleForm.email = null
              saleForm.companyName = null
              saleForm.industry = null
              saleForm.companyType = null
              saleForm.comment = null
              saleForm.country = country.filter(item=> item.code == lan[locale.value])[0]?.value
              isAgree.value = false
            } else {
              ElMessage({
                message:  t('form.submit.fail'),
                type: 'error',
                offset: 200,
              })
            }
          })
        }
      } else {
        ElMessage({
          message: t('form.error.policyNull'),
          type: 'error',
          offset: 200,
        })
      }
    } else {
      // console.log('sb');
    }
  })
}

const switchTab = (path) => {
  window.routerPush(path)
}

</script>

<style scoped>
:deep(.el-textarea__inner) {
  height: 4.6vw;
  /* height: 7.6vw; */
}

.prov_inner .form :deep(.el-input__wrapper) {
  padding: 0 1.04vw;
}

.prov_inner .form :deep(.el-input__inner) {
  height: 2.6vw;
  font-weight: 300;
}

.prov_inner .form :deep(.el-select__wrapper) {
  height: 2.6vw;
  font-weight: 300;
}

:deep(.el-cascader),
.prov_inner .form :deep(.el-select--large) {
  width: 100%;
}


@media screen and (max-width: 1024px), (pointer: coarse) {
  .sel :deep(.el-input__wrapper) {
    width: calc(100vw - 80px);
  }

  .prov_inner .form :deep(.el-input__inner) {
    height: 40px;
    font-size: 13px;
    padding-left: 15px;
  }

  :deep(.el-textarea__inner) {
    height: 120px;
  }

  .prov_inner .form.query {
    padding: 35px 24px;
    height: auto;
  }

  .prov_inner .form.query .prov_ipt {
    height: 40px;
    padding-left: 20px;
    font-size: 12px;
  }

  .prov_inner .form.query .more {
    height: 40px;
    width: 70px;
    right: 24px;
    font-size: 12px;
  }

  .prov_inner .form {
    height: 70vh;
    overflow: auto;
  }

  .prov_inner .form .block .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .prov_inner .form .tit span,
  .prov_inner .form .tit {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .prov_page .part1 {
    padding: 20px;
  }

  .prov_page .part1 .list .item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 15px;
  }

  .prov_page .part1 .list .item .tit {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .prov_page .part1 .list .item .con {
    font-size: 13px;
  }

  .prov_page .banner .tab .item::after {
    opacity: 1;
  }
}
</style>