const country = [
    {"value": "Afghanistan", "code": "AF"},
    {"value": "Aland Islands", "code": "AX"},
    {"value": "Albania", "code": "AL"},
    {"value": "Algeria", "code": "DZ"},
    {"value": "American Samoa", "code": "AS"},
    {"value": "Andorra", "code": "AD"},
    {"value": "Angola", "code": "AO"},
    {"value": "Anguilla", "code": "AI"},
    {"value": "Antigua & Barbuda", "code": "AG"},
    {"value": "Argentina", "code": "AR"},
    {"value": "Armenia", "code": "AM"},
    {"value": "Aruba", "code": "AW"},
    {"value": "Australia", "code": "AU"},
    {"value": "Austria", "code": "AT"},
    {"value": "Azerbaijan", "code": "AZ"},
    {"value": "Bahamas (The)", "code": "BS"},
    {"value": "Bahrain", "code": "BH"},
    {"value": "Bangladesh", "code": "BD"},
    {"value": "Barbados", "code": "BB"},
    {"value": "Belarus", "code": "BY"},
    {"value": "Belgium", "code": "BE"},
    {"value": "Belize", "code": "BZ"},
    {"value": "Benin", "code": "BJ"},
    {"value": "Bermuda", "code": "BM"},
    {"value": "Bhutan", "code": "BT"},
    {"value": "Bolivia", "code": "BO"},
    {"value": "Bosnia & Herzegovina", "code": "BA"},
    {"value": "Botswana", "code": "BW"},
    {"value": "Brazil", "code": "BR"},
    {"value": "British Indian Ocean Territory", "code": "IO"},
    {"value": "British Virgin Islands", "code": "BV"},
    {"value": "Brunei", "code": "BN"},
    {"value": "Bulgaria", "code": "BG"},
    {"value": "Burkina", "code": "BF"},
    {"value": "Burundi", "code": "BI"},
    {"value": "Côte d'Ivoire", "code": "CI"},
    {"value": "Cambodia", "code": "KH"},
    {"value": "Cameroon", "code": "CM"},
    {"value": "Canada", "code": "CA"},
    {"value": "Cape Verde", "code": "CV"},
    {"value": "Caribbean Netherlands", "code": "BQ"},
    {"value": "Cayman Islands", "code": "KY"},
    {"value": "Central African Republic", "code": "CF"},
    {"value": "Chad", "code": "TD"},
    {"value": "Chile", "code": "CL"},
    {"value": "China", "code": "CN"},
    {"value": "Christmas Island", "code": "CX"},
    {"value": "Cocos (Keeling) Islands", "code": "CC"},
    {"value": "Colombia", "code": "CO"},
    {"value": "Cook Islands", "code": "CK"},
    {"value": "Costa Rica", "code": "CR"},
    {"value": "Croatia", "code": "HR"},
    {"value": "Cuba", "code": "CU"},
    {"value": "Cyprus", "code": "CY"},
    {"value": "Czech Republic", "code": "CZ"},
    {"value": "Democratic Republic of the Congo", "code": "CD"},
    {"value": "Denmark", "code": "DK"},
    {"value": "Djibouti", "code": "DJ"},
    {"value": "Dominica", "code": "DM"},
    {"value": "Dominican Republic", "code": "DO"},
    {"value": "Ecuador", "code": "EC"},
    {"value": "Egypt", "code": "EG"},
    {"value": "El Salvador", "code": "SV"},
    {"value": "Equatorial Guinea", "code": "GQ"},
    {"value": "Eritrea", "code": "ER"},
    {"value": "Estonia", "code": "EE"},
    {"value": "Ethiopia", "code": "ET"},
    {"value": "Falkland Islands", "code": "FK"},
    {"value": "Faroe Islands", "code": "FO"},
    {"value": "Federated States of Micronesia", "code": "FM"},
    {"value": "Fiji", "code": "FJ"},
    {"value": "Finland", "code": "FI"},
    {"value": "France", "code": "FR"},
    {"value": "French Guiana", "code": "GF"},
    {"value": "French polynesia", "code": "PF"},
    {"value": "French Southern Territories", "code": "TF"},
    {"value": "Gabon", "code": "GA"},
    {"value": "Gambia", "code": "GM"},
    {"value": "Georgia", "code": "GE"},
    {"value": "Germany", "code": "DE"},
    {"value": "Ghana", "code": "GH"},
    {"value": "Gibraltar", "code": "GI"},
    {"value": "Great Britain (United Kingdom; England)", "code": "UK"},
    {"value": "Greece", "code": "GR"},
    {"value": "Greenland", "code": "GL"},
    {"value": "Grenada", "code": "GD"},
    {"value": "Guadeloupe", "code": "GP"},
    {"value": "Guam", "code": "GU"},
    {"value": "Guatemala", "code": "GT"},
    {"value": "Guernsey", "code": "GG"},
    {"value": "Guinea", "code": "GN"},
    {"value": "Guinea-Bissau", "code": "GW"},
    {"value": "Guyana", "code": "GY"},
    {"value": "Haiti", "code": "HT"},
    {"value": "Honduras", "code": "HN"},
    {"value": "Hong Kong", "code": "HK"},
    {"value": "Hungary", "code": "HU"},
    {"value": "Iceland", "code": "IS"},
    {"value": "India", "code": "IN"},
    {"value": "Indonesia", "code": "ID"},
    {"value": "Iran", "code": "IR"},
    {"value": "Iraq", "code": "IQ"},
    {"value": "Ireland", "code": "IE"},
    {"value": "Isle of Man", "code": "IM"},
    {"value": "Israel", "code": "IL"},
    {"value": "Italy", "code": "IT"},
    {"value": "Jamaica", "code": "JM"},
    {"value": "Japan", "code": "JP"},
    {"value": "Jersey", "code": "JE"},
    {"value": "Jordan", "code": "JO"},
    {"value": "Kazakhstan", "code": "KZ"},
    {"value": "Kenya", "code": "KE"},
    {"value": "Kiribati", "code": "KI"},
    {"value": "Kuwait", "code": "KW"},
    {"value": "Kyrgyzstan", "code": "KG"},
    {"value": "Laos", "code": "LA"},
    {"value": "Latvia", "code": "LV"},
    {"value": "Lebanon", "code": "LB"},
    {"value": "Lesotho", "code": "LS"},
    {"value": "Liberia", "code": "LR"},
    {"value": "Libya", "code": "LY"},
    {"value": "Liechtenstein", "code": "LI"},
    {"value": "Lithuania", "code": "LT"},
    {"value": "Luxembourg", "code": "LU"},
    {"value": "Macao", "code": "MO"},
    {"value": "Madagascar", "code": "MG"},
    {"value": "Malawi", "code": "MW"},
    {"value": "Malaysia", "code": "MY"},
    {"value": "Maldives", "code": "MV"},
    {"value": "Mali", "code": "ML"},
    {"value": "Malta", "code": "MT"},
    {"value": "Marshall islands", "code": "MH"},
    {"value": "Martinique", "code": "MQ"},
    {"value": "Mauritania", "code": "MR"},
    {"value": "Mauritius", "code": "MU"},
    {"value": "Mayotte", "code": "YT"},
    {"value": "Mexico", "code": "MX"},
    {"value": "Moldova", "code": "MD"},
    {"value": "Monaco", "code": "MC"},
    {"value": "Mongolia", "code": "MN"},
    {"value": "Montenegro", "code": "ME"},
    {"value": "Montserrat", "code": "MS"},
    {"value": "Morocco", "code": "MA"},
    {"value": "Mozambique", "code": "MZ"},
    {"value": "Myanmar (Burma)", "code": "MM"},
    {"value": "Namibia", "code": "NA"},
    {"value": "Nauru", "code": "NR"},
    {"value": "Nepal", "code": "NP"},
    {"value": "Netherlands", "code": "NL"},
    {"value": "New Caledonia", "code": "NC"},
    {"value": "New Zealand", "code": "NZ"},
    {"value": "Nicaragua", "code": "NI"},
    {"value": "Niger", "code": "NE"},
    {"value": "Nigeria", "code": "NG"},
    {"value": "Niue", "code": "NU"},
    {"value": "Norfolk Island", "code": "NF"},
    {"value": "North Korea", "code": "KP"},
    {"value": "Northern Mariana Islands", "code": "MP"},
    {"value": "Norway", "code": "NO"},
    {"value": "Oman", "code": "OM"},
    {"value": "Pakistan", "code": "PK"},
    {"value": "Palau", "code": "PW"},
    {"value": "Palestinian territories", "code": "PS"},
    {"value": "Panama", "code": "PA"},
    {"value": "Papua New Guinea", "code": "PG"},
    {"value": "Paraguay", "code": "PY"},
    {"value": "Peru", "code": "PE"},
    {"value": "Pitcairn Islands", "code": "PN"},
    {"value": "Poland", "code": "PL"},
    {"value": "Portugal", "code": "PT"},
    {"value": "Puerto Rico", "code": "PR"},
    {"value": "Qatar", "code": "QA"},
    {"value": "Republic of Macedonia (FYROM)", "code": "MK"},
    {"value": "Republic of the Congo", "code": "CG"},
    {"value": "Réunion", "code": "RE"},
    {"value": "Romania", "code": "RO"},
    {"value": "Russian Federation", "code": "RU"},
    {"value": "Rwanda", "code": "RW"},
    {"value": "Saint Barthélemy", "code": "BL"},
    {"value": "Saint Martin (France)", "code": "MF"},
    {"value": "Saint-Pierre and Miquelon", "code": "PM"},
    {"value": "Samoa", "code": "WS"},
    {"value": "San Marino", "code": "SM"},
    {"value": "Sao Tome & Principe", "code": "ST"},
    {"value": "Saudi Arabia", "code": "SA"},
    {"value": "Senegal", "code": "SN"},
    {"value": "Serbia", "code": "RS"},
    {"value": "Seychelles", "code": "SC"},
    {"value": "Sierra Leone", "code": "SL"},
    {"value": "Singapore", "code": "SG"},
    {"value": "Slovakia", "code": "SK"},
    {"value": "Slovenia", "code": "SI"},
    {"value": "Solomon Islands", "code": "SB"},
    {"value": "Somalia", "code": "SO"},
    {"value": "South Africa", "code": "ZA"},
    {"value": "South Georgia and the South Sandwich Islands", "code": "GS"},
    {"value": "South Korea", "code": "KR"},
    {"value": "South Sudan", "code": "SS"},
    {"value": "Spain", "code": "ES"},
    {"value": "Sri Lanka", "code": "LK"},
    {"value": "St. Helena & Dependencies", "code": "SH"},
    {"value": "St. Kitts & Nevis", "code": "KN"},
    {"value": "St. Lucia", "code": "LC"},
    {"value": "St. Vincent & the Grenadines", "code": "VC"},
    {"value": "Sudan", "code": "SD"},
    {"value": "Suriname", "code": "SR"},
    {"value": "Swaziland", "code": "SZ"},
    {"value": "Sweden", "code": "SE"},
    {"value": "Switzerland", "code": "CH"},
    {"value": "Syria", "code": "SY"},
    {"value": "Taiwan", "code": "TW"},
    {"value": "Tajikistan", "code": "TJ"},
    {"value": "Tanzania", "code": "TZ"},
    {"value": "Thailand", "code": "TH"},
    {"value": "The Comoros", "code": "KM"},
    {"value": "The Philippines", "code": "PH"},
    {"value": "Timor-Leste (East Timor)", "code": "TL"},
    {"value": "Togo", "code": "TG"},
    {"value": "Tokelau", "code": "TK"},
    {"value": "Tonga", "code": "TO"},
    {"value": "Trinidad & Tobago", "code": "TT"},
    {"value": "Tunisia", "code": "TN"},
    {"value": "Turkey", "code": "TR"},
    {"value": "Turkmenistan", "code": "TM"},
    {"value": "Turks & Caicos Islands", "code": "TC"},
    {"value": "Tuvalu", "code": "TV"},
    {"value": "Uganda", "code": "UG"},
    {"value": "Ukraine", "code": "UA"},
    {"value": "United Arab Emirates", "code": "AE"},
    {"value": "United States Minor Outlying Islands", "code": "UM"},
    {"value": "United States of America (USA)", "code": "US"},
    {"value": "United States Virgin Islands", "code": "VI"},
    {"value": "Uruguay", "code": "UY"},
    {"value": "Uzbekistan", "code": "UZ"},
    {"value": "Vanuatu", "code": "VU"},
    {"value": "Vatican City (The Holy See)", "code": "VA"},
    {"value": "Venezuela", "code": "VE"},
    {"value": "Vietnam", "code": "VN"},
    {"value": "Wallis and Futuna", "code": "WF"},
    {"value": "Western Sahara", "code": "EH"},
    {"value": "Yemen", "code": "YE"},
    {"value": "Zambia", "code": "ZM"},
    {"value": "Zimbabwe", "code": "ZW"}
]
const lan = {'zh': 'CN', 'en': '', 'ja': 'JP', 'th': 'TH', 'ko': 'KR', 'es': 'ES', 'de': 'DE', 'fr': 'FR', 'ru': 'RU', 'en_eu': 'DE', 'en_us': 'US', 'zh_tw': 'TW'}

export {country, lan}