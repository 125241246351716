<template>
  <template v-if="isFooterShow">
    <div class="footer">
      <div class="f_top">
        <div class="left">
          <a @click="switchTab('/')" class="f_logo">
            <img :src="formatImg(baseinfo?.logo)" alt="">
          </a>
          <div>
            <div class="fx">

              <template v-for="(item) in baseinfo?.basicInformationLogos" :key="item.id">
                <a :href="item.url" target="_blank">
                  <img :src="formatImg(item.logo)" alt="" class="comm_img">
                  <img :src="formatImg(item.checkLogo)" alt="" class="act_img">
                  <div class="ewm" v-if="item.qrCode">
                    <img :src="formatImg(item.qrCode)" alt="">
                  </div>
                </a>
              </template>

            </div>

          </div>
        </div>
        <div class="right">
          <div class="tel">
            <div class="txt f_16">{{ t('index.serviceHotline') }} ：</div>
            <div class="num f_22">{{ baseinfo?.phone }}</div>
          </div>
        </div>
      </div>
      <div class="f_center">
        <ul class="left f_16">
          <template v-for="(item, index) in navs2" :key="item.id">
            <li class="li1" v-if="item.uniqueness == '产品中心'">
              <a @click="switchTab(item.router)" v-html="item.name"></a>
              <ul class="f_16">
                <template v-for="(item1) in item.children">
                  <li class="li2">
                    <a @click="switchTab(`/ProductList/${item1.name}`)" v-html="item1.name"></a>
                  </li>
                </template>
              </ul>
            </li>
            <li class="li1" v-else-if="item.uniqueness == '解决方案'">
              <div class="a" v-html="item.name"></div>
              <ul class="f_16">
                <template v-for="(item1) in item.children">
                  <li class="li2" v-if="item1.id == 63 || !item1.router.includes('industry')">
                    <a @click="switchTab(item1.router)" v-html="item1.name"></a>
                  </li>
                  <li class="li2" v-else>
                    <a @click="switchTab('/industry/' + formatUrlToLine(item1.name))" v-html="item1.name"></a>
                  </li>
                </template>
              </ul>
            </li>
            <li class="li1" v-else>
              <a @click="switchTab(item.router)" v-html="item.name"></a>
              <ul class="f_16">
                <template v-for="(item1) in item.children">
                  <li class="li2">
                    <a @click="switchTab(item1.router)" v-html="item1.name"></a>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
        <div class="right f_14">
          <div class="box">
            <div class="h">{{ t('footer.email') }}：</div>
            <div class="p">{{ baseinfo?.mailbox }}</div>
          </div>
          <div class="box">
            <div class="h">{{ t('footer.address') }}：</div>
            <div class="" style="line-height: 1.5; font-weight: 400;" v-html="baseinfo?.address"></div>
          </div>
          <!-- <div class="code">
              <img src="@/assets/img/f_code.png" alt="">
            </div> -->
        </div>
      </div>
      <div class="f_bottom f_12">
        <div class="left">
          Copyright©2023{{ baseinfo?.websiteTitle }} {{ t('footer.copyright') }} 
          <a style="margin-left: 10px;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ baseinfo?.icp }}</a>
          <a style="margin-left: 10px;" :href="baseinfo?.policeUrl" target="_blank">{{ baseinfo?.police }}</a>
        </div>
        <div class="right">
          <!-- <a @click="switchTab('/map')">网站地图</a>
          <span>|</span>
          <a @click="switchTab('/ystk')">隐私条款</a>
          <span>|</span>
          <a @click="switchTab('/flsm')">法律申明</a> -->
        </div>
      </div>
    </div>

    <div class="m_footer">
      <!-- <div class="nav">
        <ul>
          <template v-for="(item) in navs2"
                    :key="item.id">
            <li>
              <div class="first tit">
                <a @click="switchTab(item.router)"
                   v-html="item.name"></a>
                <i v-if="item.children"
                   @click="mFooter"></i>
              </div>
              <div class="sec_list">
                <template v-for="(item1) in item.children">

                  <div class="sec_item">
                    <div class="sec"><a @click="switchTab(item1.router)"
                         v-html="item1.name"></a><i v-if="item1.children"
                         @click="mFooter"></i></div>
                  </div>
                </template>
              </div>
            </li>
          </template>
        </ul>
      </div> -->
      <div class="center">
        <div class="contact">
          <img src="@/assets/imgm/phone.svg" alt="">
          <div class="txt">
            <div class="tit">{{ t('index.serviceHotline') }}</div>
            <a class="con" :href="`tel:${t('index.hotline')}`">{{ t('index.hotline') }}</a>
          </div>
        </div>
        <div class="info">
          <div class="box">
            <div class="h">{{ t('footer.email') }}：</div>
            <div class="p">{{ baseinfo?.mailbox }}</div>
          </div>
          <div class="box">
            <div class="h">{{ t('footer.address') }}：</div>
            <div class="p" v-html="baseinfo?.address"></div>
          </div>
        </div>
        <div class="share">
          <template v-for="(item) in baseinfo?.basicInformationLogos" :key="item.id">
            <a :href="item.url" target="_blank">
              <img :src="formatImg(item.mtLogo)">
            </a>
          </template>
        </div>
        <div class="copyright">
          <!-- <div><span @click="switchTab('/map')">网站地图</span> | <span @click="switchTab('/ystk')">隐私条款</span> | <span @click="switchTab('/flsm')">法律声明</span></div> -->
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">Copyright©2023{{ baseinfo?.websiteTitle }} {{
            t('footer.copyright') }} <br>
            {{ baseinfo?.icp }}</a>
          <div>
            <a :href="baseinfo?.policeUrl" target="_blank">{{ baseinfo?.police }}</a>
          </div>
        </div>
      </div>

      <div class="bottom">
      </div>
    </div>
    <div class="aside_bar">
      <a @click="switchTab('/')" class="logo"><img src="@/assets/imgm/logo.png" alt=""></a>
      <a v-if="locale == 'zh'" class="item" onclick="open_floatWindow();">
        <div class="icon">
          <img src="@/assets/imgl/akefu2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/akefu2a.svg" alt="" class="act_img">
        </div>
        <div class="tit">{{t('footer.consult')}}</div>
      </a>
      <a v-if="locale == 'zh'" class="item" @click="ctaFormClick">
        <div class="icon">
          <img src="@/assets/imgl/ayj2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/ayj2a.svg" alt="" class="act_img">
        </div>
        <div class="tit">{{t('footer.applyRobot')}}</div>
      </a>
      <a class="item" @click="backTop">
        <div class="icon">
          <img src="@/assets/imgl/aback2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/aback2a.svg" alt="" class="act_img">
        </div>
        <div class="tit">{{ t('footer.backTop') }}</div>
      </a>
    </div>
    <div class="aside_bar m">
      <!-- <a @click="switchTab('/')"
         class="logo"><img src="@/assets/imgm/logo.png"
             alt=""></a> -->
      <a v-if="locale == 'zh'" class="item" onclick="_53App.getApp('chat').openSdkUrl('float');">
        <div class="icon">
          <img src="@/assets/imgl/akefu2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/akefu2a.svg" alt="" class="act_img">
        </div>
        <!-- <div class="tit">在线咨询</div> -->
      </a>
      <a v-if="locale == 'zh'" class="item" @click="ctaFormClick">
        <div class="icon">
          <img src="@/assets/imgl/ayj2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/ayj2a.svg" alt="" class="act_img">
        </div>
        <!-- <div class="tit">申请样机</div> -->
      </a>
      <a class="item" @click="backTop">
        <div class="icon">
          <img src="@/assets/imgl/aback2.svg" alt="" class="comm_img">
          <img src="@/assets/imgl/aback2a.svg" alt="" class="act_img">
        </div>
        <!-- <div class="tit">返回顶部</div> -->
      </a>
    </div>

    <FormDialog ref="formDialogRef" :tag="'申请样机'" :title="t('footer.applyRobot')" @ctaFormClick="ctaFormClick"></FormDialog>
  </template>
  <div class="digital"></div>
</template>
<script setup>
import { ProductList_xl, solutionXl, getPrograma, getBaseInfo, serveMain, crmCommApi, checkCity } from '@/services/index.js'
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import formatImg, { formatUrlToLine } from '@/utils/formatImg.js'

import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()

import { useRouter } from 'vue-router'
const router = useRouter()
const switchTab = (path) => {
  window.routerPush(formatUrlToLine(path))
}

// 表单弹框
import FormDialog from '@/components/form-dialog.vue';
const formDialogRef = ref(null)
const ctaFormClick = () => {
  formDialogRef.value.ctaFormClick()
}

const navs2 = ref([])
const baseinfo = ref([])
const links = ref([])

let showTimer
const isFooterShow = ref(false)

onMounted(() => {
  getlist()
  init()

  showTimer = setTimeout(() => {
    isFooterShow.value = true
  }, 1000)
})

const list_p = ref(null)
const solutionList = ref([])
const getlist = () => {

  getPrograma(2).then(res => {
    console.log('getPrograma2', res);
    navs2.value = res.data
  })

  ProductList_xl(2).then(res => {
    list_p.value = res.data
  })
  solutionXl(2).then(res => {
    // console.log('solutionList', res);
    solutionList.value = res
    mFooter()
  })
  getBaseInfo().then(res => {
    // console.log('baseinfo', res);
    baseinfo.value = res.data
  })

  serveMain({
    typeId: 19,
    status: 0
  }).then(res => {
    // console.log(res)
    links.value = res.data
  })
}


const mFooter = () => {
  $(function () {
    $(".m_footer .first").unbind()
    $(".m_footer .first").click(function () {
      if ($(this).siblings('.sec_list').is(':hidden')) {
        $('.m_footer .sec_list').stop(true, false).slideUp()
        $('.m_footer .first').removeClass('on');
        $(this).addClass('on');
        $(this).siblings('.sec_list').stop().slideDown();
      } else {
        $(this).removeClass('on');
        $(this).siblings('.sec_list').stop().slideUp();
      }
    });
    $(".m_footer .sec_item").unbind()
    $(".m_footer .sec_item").click(function () {
      if ($(this).find('.third_list').is(':hidden')) {
        $(this).addClass('on');
        $(this).siblings().removeClass('on');
        $(this).find('.third_list').stop().slideDown();
        $(this).siblings().children('.third_list').stop().slideUp()
      } else {
        $(".m_footer .sec_item").removeClass('on');
        $(".m_footer .sec_item .third_list").stop(true, false).slideUp();
      }
    });
  })
}

const init = () => {
  $(function () {
    window.addEventListener('scroll', function () {
      // console.log(window.pageYOffset);
      if (window.pageYOffset > 100) {
        $('.aside_bar').addClass('act')
      } else {
        $('.aside_bar').removeClass('act')
      }
    })


  })
}

const backTop = () => {
  $(function () {
    $('html,body').animate({
      scrollTop: 0
    }, 500);
  })
}


onBeforeUnmount(() => {
  clearTimeout(showTimer)
})
</script>
<style scoped>
.aside_bar {
  position: fixed;
  bottom: 15%;
  right: 0;
  transform: translateX(100%);
  /* width: 3.65vw; */
  transition: all 0.5s;
  background-color: #fff;
  border-radius: 0.52vw 0 0 0.52vw;
  box-shadow: 0 0.16vw 2.6vw rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 998;
}

.aside_bar.act {
  transform: translateX(0);
}

.aside_bar .logo,
.aside_bar .item {
  width: 100%;
  height: 3.65vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s;
  padding: 0 10px;
}

.aside_bar .item {
  border-top: 0.05vw solid #f0f0f0;
  white-space: nowrap;
}

.aside_bar .logo img {
  width: 2.92vw;
}

.aside_bar .item:hover {
  background-color: #d80c1e;
}

.aside_bar .item img {
  margin-bottom: 0.31vw;
  max-width: 1.04vw;
  min-height: 1.04vw;
}

.aside_bar .item .act_img {
  display: none;
}

.aside_bar .item:hover .act_img {
  display: block;
}

.aside_bar .item:hover .comm_img {
  display: none;
}

.aside_bar .item .tit {
  font-size: 0.73vw;
  font-weight: 400;

  color: #333;
  transition: all 0.5s;
}

.aside_bar .item:hover .tit {
  color: #fff;
}

.formPart .right .item.sel :deep(.el-input__inner) {
  width: 11.8vw;
}

.prov_inner :deep(.el-textarea__inner) {
  padding: 0.15rem 0.17rem;
}

:deep(.el-textarea__inner) {
  height: 4.6vw;
  /* height: 7.6vw; */
}

.prov_inner .form :deep(.el-input__wrapper) {
  padding: 0 1.04vw;
}

.prov_inner .form :deep(.el-input__inner) {
  height: 2.6vw;
  font-family: 300;
}

.prov_inner .form :deep(.el-select__wrapper) {
  height: 2.6vw;
  font-weight: 300;
}

.prov_inner .form :deep(.el-select--large) {
  width: 100%;
}

.aside_bar.m {
  display: none;
}

@media screen and (max-width: 1024px), (pointer: coarse) {
  .aside_bar {
    display: none;
    border-radius: 4px 0 0 4px;
  }

  .aside_bar.m {
    display: block;
  }

  .aside_bar .logo,
  .aside_bar .item {
    height: auto;
    padding: 15px;
  }

  .aside_bar .logo img {
    width: 45px;
  }

  .aside_bar .item img {
    max-width: 20px;
    /* max-height: 16px; */
    /* margin-bottom: 10px; */
  }

  .aside_bar .item:hover {
    background-color: #fff;
  }

  .aside_bar .item:hover .act_img {
    display: none;
  }

  .aside_bar .item:hover .comm_img {
    display: block;
  }

  .aside_bar .item .tit {
    font-size: 12px;
  }

  .prov_page .banner {
    margin-top: 60px;
  }

  .prov_page .banner .txt .con {
    font-size: 15px;
  }

  .prov_page .banner {
    height: 161.7vw;
  }

  .sel :deep(.el-input__wrapper) {
    width: calc(100vw - 40px);
  }

  .prov_inner .form {
    height: 70vh;
    overflow: auto;
  }

  .sel :deep(.el-input__wrapper) {
    width: calc(100vw - 80px);
  }

  .prov_inner .form :deep(.el-input__inner) {
    height: 40px;
  }

  .prov_inner .form :deep(.el-input__wrapper) {
    padding: 0 15px;
  }

  .prov_inner .form .block .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .prov_inner .form .tit span,
  .prov_inner .form .tit {
    font-size: 14px;
    margin-bottom: 5px;
  }

  :deep(.el-textarea__inner) {
    height: 120px;
  }
}
</style>